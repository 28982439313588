
import React from 'react'
import './foot.css'
const Footer = () => {
    return(
        <div className='footer'>
            <br />
            <h4>© James Hutchins 2022</h4>



        </div>
    )
}
export default Footer